import React from 'react';
import { Link } from 'react-router-dom';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Box, Hidden, IconButton } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';

import sidebarLogo from 'assets/eficia/logos/sidebar_logo.svg';
import { LOGGED_PATH } from 'eficia/routes/paths';

import { HeaderSelect } from './components/select';
import { AppHeader, MenuButtonContainer } from './Header.style';

function Header({ sidebarToggleMobile, setSidebarToggleMobile }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <AppHeader elevation={1} data-testid="header">
      <Hidden lgUp>
        {/* mobile */}
        <MenuButtonContainer>
          <IconButton
            color="inherit"
            onClick={() => setSidebarToggleMobile(!sidebarToggleMobile)}
            size="large"
          >
            {sidebarToggleMobile ? <MenuOpenRoundedIcon /> : <MenuRoundedIcon />}
          </IconButton>
        </MenuButtonContainer>
      </Hidden>
      <Hidden lgDown>
        {/* desktop */}
        <Link to={LOGGED_PATH} style={{ padding: '24px 22px 24px 22px' }}>
          <img src={sidebarLogo} height="32" alt="Eficia" />
        </Link>
      </Hidden>
      <Box
        className="d-flex align-items-center"
        style={{ position: 'relative', left: -17, top: 5 }}
      >
        {!isMobile && <HeaderSelect />}
      </Box>
    </AppHeader>
  );
}

Header.propTypes = {
  setSidebarToggleMobile: PropTypes.func,
  sidebarToggleMobile: PropTypes.bool
};

export default Header;

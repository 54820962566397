import React, { useContext } from 'react';
import { CircleFlag } from 'react-circle-flags';
import { MenuItem, Select } from '@mui/material';

import { TranslationContext } from 'eficia/contexts/TranslationProvider';

import { FlagContainer, Input } from './HeaderSelect.style';

export default function HeaderSelect() {
  const { currentLang, supportedLangs, changeLang } = useContext(TranslationContext);

  const handleChange = (event) => {
    event.preventDefault();
    changeLang(supportedLangs.find((supportedLang) => supportedLang.iso === event.target.value));
  };

  const getFlag = (lang) => <CircleFlag countryCode={lang.countryCode} height={18} />;

  return (
    <Select
      variant="outlined"
      className="px-3"
      value={currentLang.iso}
      onChange={handleChange}
      input={<Input />}
      renderValue={(value) =>
        supportedLangs &&
        getFlag(supportedLangs.find((supportedLang) => supportedLang.iso === value))
      }
    >
      {supportedLangs &&
        supportedLangs.map((supportedLang, index) => (
          <MenuItem
            key={index}
            value={supportedLang.iso}
            className="d-flex flex-row align-items-center"
            style={{ gap: 4 }}
          >
            <FlagContainer>{getFlag(supportedLang)}</FlagContainer>
            <span> </span>
            <span style={{ fontSize: 12, textTransform: 'uppercase' }}>
              ({supportedLang.iso.toUpperCase()})
            </span>
          </MenuItem>
        ))}
    </Select>
  );
}

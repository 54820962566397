import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import moment from 'moment';
import LanguageDetector from 'i18next-browser-languagedetector';
import PropTypes from 'prop-types';
import am5LocalesFR from '@amcharts/amcharts5/locales/fr_FR';
import am5LocalesES from '@amcharts/amcharts5/locales/es_ES';
import am5LocalesEN from '@amcharts/amcharts5/locales/en_US';
import am5LocalesIT from '@amcharts/amcharts5/locales/it_IT';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/it';

import { useFetchTranslations } from './services/translation/useFetchTranslations';

// Langue par défaut de l'Admin Panel (historiquement le français)
const DEFAULT_ISO = 'fr';

// Par défaut nous essayons de configurer les nouvelles langues au best-effort
// Mais il est parfois nécessaire de les configurer manuellement pour certains paramètres
// Lister ici les paramètres à surcharger pour chaque langue
const OVERRIDE_LANGS_SETTINGS = {
  fr: {
    am5Locales: am5LocalesFR
  },
  en: {
    locale: 'en-US',
    // Choix de la Grande-Bretagne pour l'anglais
    countryCode: 'gb',
    // Moment.js utilise en-GB pour l'anglais (en-US est aussi disponible)
    momentLocale: 'en-GB',
    am5Locales: am5LocalesEN
  },
  es: {
    am5Locales: am5LocalesES
  },
  it: {
    am5Locales: am5LocalesIT
  }
};

// Factory afin de créer une langue à partir de son code ISO (+ paramètres de surcharge)
const createLang = (iso) => ({
  iso,
  locale: `${iso}-${iso.toUpperCase()}`,
  countryCode: iso,
  momentLocale: iso,
  ...(OVERRIDE_LANGS_SETTINGS[iso] || OVERRIDE_LANGS_SETTINGS[DEFAULT_ISO])
});

// La liste des langues supportées par l'Admin Panel est en dûr pour l'instant
const supportedLangs = [
  createLang(DEFAULT_ISO),
  createLang('en'),
  createLang('es'),
  createLang('it')
];

// La langue par défaut durant le chargement de l'Admin Panel (avant de connaitre celle de l'utilisateur)
// C'est aussi la langue par défaut lorsque l'utilisateur n'a pas encore changé celle-ci
export const DEFAULT_LANG = createLang(DEFAULT_ISO);

// Moment est utilisé pour la gestion des dates et peut afficher une date localisée
moment.locale(DEFAULT_LANG.momentLocale);

// Cette variable va contenir toutes les traductions nécessaire à i18next
// Le téléchargement des traductions est asynchrone et se fait au court du chargement de l'Admin Panel
const langResources = {};

if (!i18n.isInitialized) {
  // Très important : ne doit être initié qu'une seule fois
  i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .use(LanguageDetector)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      lng: DEFAULT_LANG.locale,
      keySeparator: true,
      resources: langResources,
      returnEmptyString: false,
      parseMissingKeyHandler: (key, defaultValue) => {
        if (!defaultValue) {
          if (Object.keys(langResources).length === 0) {
            // Les traductions sont en cours de chargement
            return '';
          }

          // Affiche la clef de traduction is manquante
          return langResources?.[DEFAULT_LANG.locale]?.translation?.[key] || key;
        }
      },
      react: {
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
        useSuspense: true
      }
    });
}

// L'Admin Panel se base sur le cookie "userLang" afin de déterminer la langue de l'interface
const TranslationContext = React.createContext();
const { Provider } = TranslationContext;
function TranslationProvider({ children }) {
  const [cookies, setCookie] = useCookies();
  const { userLang } = cookies;

  const [currentLang, setCurrentLang] = useState(
    supportedLangs.find((supportedLang) => supportedLang.iso === userLang) || DEFAULT_LANG
  );

  const { data: translationData } = useFetchTranslations({
    defaultLocale: DEFAULT_LANG.locale,
    currentLocale: currentLang.locale
  });

  useEffect(() => {
    if (translationData && supportedLangs?.length) {
      Object.keys(translationData).forEach((langIso) => {
        langResources[langIso] = { translation: translationData[langIso] };
      });

      // Forcer la prise en compte des traductions fraichement téléchargées
      moment.locale(currentLang.momentLocale);
      i18n.changeLanguage(currentLang.locale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translationData, supportedLangs, currentLang]);

  const changeLang = (newLang) => {
    setCookie('userLang', newLang.iso);

    // Il est plus pratique et simple de rafrachir toute la page lors d'un changement de langue
    // Nous prenons l'hypothése qu'un changement de langue est occasionel pour un utilisateur
    // amCharts est exigeant pour changer de langue sans rafraichir la page : il souhaite être redessiné pour prendre en compte la nouvelle langue
    window.location.reload();
  };

  return <Provider value={{ currentLang, supportedLangs, changeLang }}>{children}</Provider>;
}
TranslationProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { TranslationContext, TranslationProvider };

import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { FilterButtonUi } from './FilterButton.style';

export default function FilterButton({ onFilterClick, isActive }) {
  const { t } = useTranslation();
  return (
    <FilterButtonUi
      color="inherit"
      onClick={onFilterClick}
      size="small"
      startIcon={<i className="fa-solid fa-filter" />}
      disableRipple
      label={t('alert.view.table.filter')}
      className={clsx({
        isActive
      })}
    />
  );
}

FilterButton.propTypes = {
  isActive: PropTypes.bool,
  onFilterClick: PropTypes.func.isRequired
};

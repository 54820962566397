import userApi from 'eficia/services/user';

import { LOGGED_PATH } from '../paths';

// Redirige l'**utilisateur connecté** vers l'accueil de l'admin
// Ce loader est adapté à une page de type Signin / Signup / ...
export const redirectLoggedUser = async () => {
  try {
    const data = await userApi().isConnected();
    if (data?.data?.data?.isConnected) {
      const redirect = () => {
        window.location.href = `/#${LOGGED_PATH}`;
      };

      if (process.env.REACT_APP_EFICIA_ENVIRONMENT === 'development') {
        // En local (uniquement) :
        // `redirectLoggedUser` est utilisée en tant que `loader` dans certaines routes
        // Mais ceci n'est pas/plus accepté par React Router, générant un warning
        // Afin de contourner ce warning, on déclenche la redirection de manière asynchrone
        // Effet de bord : La route qui n'est pas sensée être chargée est chargée un fragment de seconde
        // TODO: Trouver une meilleure solution
        window.setTimeout(() => {
          redirect();
        });
        return null;
      }

      redirect();
    }
    return null;
  } catch {
    // Si la requête échoue alors rentrer en mode dégradé et ainsi éviter une boucle de redirections
    return null;
  }
};

import axiosConfig from './config/axiosConfig';
import { GROUPS_API } from './config/constants';

export default function groupsApi() {
  return {
    async fetchFull(params) {
      const query = await axiosConfig.post(`${GROUPS_API}/get-group-full-list`, params);
      return query;
    },
    async fetch(params) {
      const query = await axiosConfig.post(
        `${GROUPS_API}/get-group-list`,
        params
        // config
      );
      return query;
    },
    async edit(params) {
      const query = await axiosConfig.post(`${GROUPS_API}/edit-group`, params);
      return query;
    },
    async fetchOne(params) {
      const query = await axiosConfig.post(`${GROUPS_API}/get-group`, params);
      return query;
    }
  };
}

import React from 'react';

import { lazyWithRetry } from 'eficia/utilities/lazyWithRetry';

import { Layout } from '../Layout';
import { GAS_METERS_HOME_PATH, GROUPS_HOME_PATH, PERSONALIZED_PAGES_HOME_PATH } from '../paths';
import { configurationRoutes } from './configuration';
import { homepageRoutes } from './homepage';

// Ici sont définies les routes de l'application
// Si vous avez un groupe de routes, vous pouvez les séparer dans un fichier et les importer ici
export const routes = [
  {
    path: '/', // Root path
    element: <Layout />,
    children: [
      ...homepageRoutes,
      ...configurationRoutes,
      {
        path: GROUPS_HOME_PATH,
        lazy: () => lazyWithRetry(() => import('eficia/views/groups/GroupsHomePage'))
      },
      {
        path: GAS_METERS_HOME_PATH,
        lazy: () => lazyWithRetry(() => import('eficia/views/gasmeters/GasMetersHomePage'))
      },
      {
        path: PERSONALIZED_PAGES_HOME_PATH,
        lazy: () =>
          lazyWithRetry(
            () => import('eficia/views/personalized_pages/PersonalizedPagesDispatchPage')
          )
      }
    ]
  }
];

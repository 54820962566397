import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';

const UserContext = React.createContext();
const { Provider } = UserContext;

function UserProvider({ children }) {
  const [cookies, setCookie] = useCookies();
  const [userFilters, setUserFilters] = useState({});
  const changeUserFilters = useCallback((filters) => {
    setUserFilters(filters);
  }, []);

  const userPerPageCookieName = 'perPage';
  const [userPerPage, setUserPerPage] = useState(
    cookies?.[userPerPageCookieName] ? parseInt(cookies[userPerPageCookieName]) : null
  );
  useEffect(() => {
    if (userPerPage) {
      setCookie(userPerPageCookieName, parseInt(userPerPage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPerPage]);

  const contextValue = {
    userFilters,
    changeUserFilters,
    userPerPage,
    setUserPerPage
  };

  return <Provider value={contextValue}>{children}</Provider>;
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { UserContext, UserProvider };

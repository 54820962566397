/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import MuiAlert from '@mui/material/Alert';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';

import CloseIcon from 'assets/eficia/icons/close.svg';
import CheckIcon from 'assets/eficia/icons/check.svg';

// import useNotification from 'eficia/hooks/useNotification';

import { Button } from 'eficia/components/atoms/Button';
import { Text } from './styles/Notification.style';

const Notification = React.forwardRef((props, ref) => {
  const { notification } = props;

  const { closeSnackbar } = useSnackbar();

  const handleClose = (event, reason) => {
    closeSnackbar();
  };

  if (!notification) {
    return null;
  }

  return (
    <MuiAlert
      ref={ref}
      elevation={6}
      onClose={handleClose}
      className="alerts-alternate"
      severity={notification.type}
      // style={{
      //   backgroundColor: 'white',
      //   color: 'black'
      // }}
    >
      <div className="d-flex align-items-stretch justify-content-center">
        <div style={{ marginTop: 1 }}>
          {notification.type === 'success' && (
            <img className="mr-3" height={20} src={CheckIcon} alt="Check Icon" />
          )}
          {notification.type === 'error' && (
            <img className="mr-3" height={20} src={CloseIcon} alt="Close Icon" />
          )}
        </div>
        <div
          style={{ maxWidth: 360, gap: 6, marginTop: 2 }}
          className="d-flex flex-row flex-wrap justify-content-flex-start"
        >
          {notification.title && (
            <Text
              style={{ fontWeight: 'bold' }}
              dangerouslySetInnerHTML={{
                __html: notification.title?.toString()
              }}
            />
          )}
          <Text
            dangerouslySetInnerHTML={{
              __html: notification.message?.toString()
            }}
          />

          {notification.link && (
            <Button
              style={{ marginLeft: -5 }}
              variant="text"
              size="small"
              component={notification.link.onClick ? undefined : Link}
              onClick={notification.link.onClick}
              to={notification.link.to}
              label={notification.link.title}
            />
          )}
        </div>
      </div>
    </MuiAlert>
    //   </Slide>
    // </Snackbar>
  );
});

Notification.propTypes = {
  // id: PropTypes.number.isRequired,
  notification: PropTypes.object
};

export default Notification;

import { stringifyQuery } from 'eficia/utilities/query';
import { isIOS } from 'eficia/utilities/platform';

export function redirectToPreviousPage(navigate, pathname, page) {
  navigate(
    `${pathname}${stringifyQuery({
      page: parseInt(page) <= 1 ? 1 : page - 1
    })}`
  );
}

export function handleExternalRedirection(url) {
  if (isIOS()) {
    const linkElement = document.createElement('a');
    linkElement.id = 'link';
    window.document.body.appendChild(linkElement);
    const link = document.getElementById('link');
    link.setAttribute('href', url);
    link.click();
  } else {
    // Le window.setTimeout permet de by-passer le bloquage de la popup par Firefox en executant window.open sur le thread principal
    // Source: https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari/70463940#70463940
    window.setTimeout(() => {
      window.open(url, '_blank').focus();
    });
  }
}
